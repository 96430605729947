/* eslint-disable default-case */

import React, { useEffect, useState } from 'react';
import API, { headers, URL_MAP } from '../../API';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import { InfoBox } from '../InfoBox';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useFilter } from '../../providers';

export default function ContentCard(props) {
  const [rawCols, setRawCols] = useState([]);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [defIndex, setDefIndex] = useState(0);
  const [cardDef, setCardDef] = useState(null);
  const [loadedCardDef, setLoadedCardDef] = useState(false);

  const { filter, getFilter } = useFilter();
  const { t } = useTranslation('translation');

  const forceRefresh = () => {
    setRefreshToggle((prev) => !prev);
  };

  const getData = (url, params, headers) => {
    return API.get(url, {
      params,
      headers,
      withCredentials: true,
      paramsSerializer: {
        indexes: null,
      },
    });
  };

  useEffect(() => {
    if (props.multi) {
      setCardDef(props.defs[defIndex]);
      forceRefresh();
    } else {
      setCardDef(props);
    }
    setLoadedCardDef(true);
  }, [defIndex, props, props.multi]);

  useEffect(() => {
    let isAlive = true;
    const params = getFilter();

    async function fetchData() {
      if (loadedCardDef) {
        setData(null);
        setRawCols([]);
        setLoaded(false);
        setIsEmpty(false);
        /* istanbul ignore else */
        if (
          moment(filter.starting_date).isValid() &&
          moment(filter.end_date).isValid()
        ) {
          if (!cardDef.pivotData && !cardDef.mergeData) {
            const result = await getData(
              `${URL_MAP[cardDef.api.module]}${cardDef.api.path}`,
              {
                ...params,
                format: 'json',
                ...cardDef.api.queryparams,
              },
              {
                ...headers[cardDef.api.module],
              }
            );

            /* istanbul ignore else */
            if (isAlive) {
              if (result) {
                if (result.status === 204) {
                  setLoaded(true);
                  setIsEmpty(true);
                } else {
                  const datum = cardDef.api.data_wrapper(
                    result.data,
                    cardDef.api.data_wrapper_opts,
                    t
                  );
                  setIsEmpty(checkIsDataEmpty(datum));
                  setData(datum);
                  if (cardDef.include_all_raw_cols) {
                    const specifiedKeys = cardDef.columns.map((col) => col.key);
                    setRawCols(
                      Object.keys(datum[0]).filter(
                        (key) => ![...specifiedKeys, 'classes'].includes(key)
                      )
                    );
                  }
                }
              }
              setLoaded(true);
              setLastUpdate(moment().format('HH[h]mm'));
            }
          } else {
            if (cardDef.pivotData) {
              const mainDataPromise = getData(
                `${URL_MAP[cardDef.api.module]}${cardDef.api.path}`,
                {
                  ...params,
                  format: 'json',
                  ...cardDef.api.queryparams,
                },
                { ...headers[cardDef.api.module] }
              );

              const toPivotDataPromise = getData(
                `${URL_MAP[cardDef.pivotData.api.module]}${
                  cardDef.pivotData.api.path
                }`,
                {
                  ...params,
                  format: 'json',
                  ...cardDef.pivotData.api.queryparams,
                },
                { ...headers[cardDef.pivotData.api.module] }
              );

              const results = await Promise.all([
                mainDataPromise,
                toPivotDataPromise,
              ]);

              /* istanbul ignore else */
              if (isAlive) {
                if (results[0].status === 204 || results[1].status === 204) {
                  setLoaded(true);
                  setIsEmpty(true);
                } else {
                  setIsEmpty(
                    checkIsDataEmpty(results[0].data) ||
                      checkIsDataEmpty(results[1].data)
                  );

                  // Build data with pivot
                  const mainData = cardDef.api.data_wrapper(
                    results[0].data,
                    cardDef.api.data_wrapper_opts,
                    t
                  );

                  const toPivotData = cardDef.api.data_wrapper(
                    results[1].data,
                    cardDef.pivotData.api.data_wrapper_opts,
                    t
                  );

                  mainData.forEach((dataline, index, self) => {
                    cardDef.pivotData.pivotable_options.forEach((pivotable) => {
                      const pivot_block_match = toPivotData.filter((block) => {
                        return (
                          block[cardDef.pivotData.pivotable_label] ===
                            pivotable &&
                          block[cardDef.pivotData.id_key] ===
                            dataline[cardDef.id_key]
                        );
                      });
                      if (pivot_block_match.length) {
                        self[index][pivotable] =
                          pivot_block_match[0][
                            cardDef.pivotData.pivotable_value
                          ];
                      } else {
                        self[index][pivotable] = 0;
                      }
                    });
                  });
                  setData(mainData);
                  setLoaded(true);
                  setLastUpdate(moment().format('HH[h]mm'));
                }
              }
            } else {
              const mainDataPromise = getData(
                `${URL_MAP[cardDef.api.module]}${cardDef.api.path}`,
                {
                  ...params,
                  format: 'json',
                  ...cardDef.api.queryparams,
                },
                { ...headers[cardDef.api.module] }
              );

              const mergeDataPromises = cardDef.mergeData.map((dataDef) =>
                getData(
                  `${URL_MAP[dataDef.api.module]}${dataDef.api.path}`,
                  {
                    ...params,
                    format: 'json',
                    ...dataDef.api.queryparams,
                  },
                  { ...headers[dataDef.api.module] }
                )
              );
              const results = await Promise.all([
                mainDataPromise,
                ...mergeDataPromises,
              ]);
              /* istanbul ignore else */
              if (isAlive) {
                if (results.every((res) => res.status === 204)) {
                  setLoaded(true);
                  setIsEmpty(true);
                } else {
                  setIsEmpty(
                    results.every((res) => checkIsDataEmpty(res.data))
                  );
                  // Build data with pivot
                  const mainData = cardDef.api.data_wrapper(
                    results[0].data,
                    cardDef.api.data_wrapper_opts,
                    t
                  );
                  const aliasedMainData = {};
                  aliasedMainData[cardDef.alias] = mainData;

                  const toMergeData = cardDef.mergeData.map((dataDef, i) => {
                    const aliasedMergeData = {};
                    aliasedMergeData[dataDef.alias] = dataDef.api.data_wrapper(
                      results[i + 1].data,
                      dataDef.api.data_wrapper_opts,
                      t
                    );
                    return aliasedMergeData;
                  });

                  let consolidatedData = Object.assign(
                    {},
                    aliasedMainData,
                    ...toMergeData
                  );
                  setData(consolidatedData);
                  setLoaded(true);
                  setLastUpdate(moment().format('HH[h]mm'));
                }
              }
            }
          }
        }
      }
    }

    fetchData();
    return () => {
      isAlive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, refreshToggle, loadedCardDef]);
  // Do not add t to useEffect as it would result in an infinite loop in some
  // contexts

  const checkIsDataEmpty = (data) => {
    switch (cardDef.component.name) {
      case 'ChartCard': {
        switch (cardDef.xtype) {
          case 'percent': {
            return data[0]?.values?.length === 0 || false;
          }
        }
        break;
      }
      case 'EvoCard': {
        switch (cardDef.xtype) {
          case 'datetime': {
            return data?.data?.length === 0 || false;
          }
        }
        break;
      }
    }

    return data.length === 0;
  };

  const handleDefChange = (_evt, value) => {
    if (value !== null) {
      setDefIndex(value);
    }
  };

  function switchComponent() {
    if (isEmpty) {
      return (
        <InfoBox
          textKey={'thereIsNoDataAvailableForThisResearch'}
          height={props.height}
        />
      );
    } else if (
      moment(filter.starting_date).isValid() &&
      moment(filter.end_date).isValid()
    ) {
      if (data && loaded) {
        const Component = cardDef.component;
        return <Component {...cardDef} data={data} rawCols={rawCols} />;
      } else {
        return <Loader height={cardDef.height || 600} />;
      }
    } else {
      return t('charts.invalid_dates');
    }
  }

  const contentCardStyle = {};

  if (cardDef) {
    if (cardDef.component.name !== 'TableCard') {
      contentCardStyle.minHeight = (cardDef.height || 600) + 100;
    }

    return (
      <div className="ContentCard" style={contentCardStyle} key={props.title}>
        <div className="ContentCard-header">
          <div className="title">
            {props.title}
            <span className="subtitle">{props.subtitle}</span>
          </div>
          {lastUpdate !== null &&
          moment(filter.starting_date).isValid() &&
          moment(filter.end_date).isValid() ? (
            <>
              {props.multi ? (
                <ToggleButtonGroup
                  value={defIndex}
                  color="primary"
                  exclusive
                  size="small"
                  sx={{
                    height: '25px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onChange={handleDefChange}
                >
                  {props.defs.map((def, index) => (
                    <ToggleButton
                      value={index}
                      size="small"
                      onClick={() => setDefIndex(index)}
                      key={def.title.replace(' ', '_')}
                    >
                      {def.title}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              ) : null}
              <div className="update-block">
                <span className="is-hidden-touch">
                  <span className="is-hidden" data-testid="refresh_seed">
                    update seed : {refreshToggle.toString()}
                  </span>
                  {t('common.updated_on')} {lastUpdate}
                </span>
                <img
                  className="refresh-icon"
                  alt="refresh"
                  onClick={forceRefresh}
                  src="/assets/icons/refresh.svg"
                />
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="ContentCard-content">{switchComponent()}</div>
      </div>
    );
  } else {
    return null;
  }
}
