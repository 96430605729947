import React from 'react';
import TableCard from '../../ContentCard/TableCard';
import ChartCard from '../../ContentCard/ChartCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { categoryWrapper, noWrap } from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';

export default function Shipping(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  function onTransporterChange(selection) {
    history.push('/shipping/details/' + selection[0].x);
  }

  const shipping_stats = {
    title: t('views.shipping.overview'),
    columns: [
      { key: 'carrier_name', label: t('views.shipping.cols.carrier_name') },
      {
        key: 'shipped_package_count',
        label: t('views.shipping.cols.shipped_package_count'),
        formatter: Formatter.number,
      },
      {
        key: 'average_total_weight_in_kg',
        label: t('views.shipping.cols.average_total_weight_in_kg'),
        formatter: Formatter.round2,
      },
      {
        key: 'avg_package_value_euro_cents',
        label: t('views.shipping.cols.avg_package_value_euro'),
        formatter: Formatter.euroCentsToEurosWithoutCents,
      },
    ],
    id_key: 'carrier_name',
    api: {
      module: 'analytics',
      path: 'carriers_distribution/v3',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
    },
    link: {
      route: '/shipping/details/',
    },
    component: TableCard,
  };

  /*
  const transport_proportion = {
    title: t('views.transport.proportion'),
    message: t('views.transport.transporter_proportion_msg'),
    onChange: onTransporterChange,
    api: {
      module: 'analytics',
      path: 'carriers_distribution/v3',
      data_wrapper: categoryWithLabelsWrapper,
      data_wrapper_opts: {
        label: 'carrier_name',
        value: 'shipped_package_count',
      },
    },
    xtype: 'percent',
    height: 300,
  };
  */
  const shipping_proportion = {
    title: t('views.shipping.proportion'),
    message: t('views.shipping.transporter_proportion_msg'),
    onChange: onTransporterChange,
    labels: {
      index: t('charts.units.transporter'),
      value: t('charts.units.packages_sent'),
    },
    formatter: Formatter.number,
    api: {
      module: 'analytics',
      path: 'carriers_distribution/v3',
      data_wrapper: categoryWrapper,
      data_wrapper_opts: {
        x: 'carrier_name',
        y: 'shipped_package_count',
        data_key: 'data',
      },
    },
    type: 'bar',
    orientation: 'horizontal',
    xtype: 'category',
    height: 300,
    component: ChartCard,
  };

  return [
    <div className="column is-full is-half-widescreen" key="shipping_stats">
      <ContentCard {...props} {...shipping_stats} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="shipping_proportion"
    >
      <ContentCard {...props} {...shipping_proportion} />
    </div>,
  ];
}
