import React, { createContext, useContext, useEffect, useState } from 'react';
import API, { headers, URL_MAP } from '../API';

const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(async () => {
    let alive = true; //
    const data = await API.get(`${URL_MAP.OMS}users/me`, {
      withCredentials: true,
      headers: { ...headers['OMS'] },
    });

    /* istanbul ignore else */
    if (alive) {
      setUser({ ...data.data?.user });
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: data.data?.user?.id,
          },
          account: {
            id: data.data?.user?.api_client_id,
          },
          excludeAllText: true,
        });
      }
    }
    /* istanbul ignore next */
    return () => {
      alive = false;
    };
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
