import React from 'react';
import TableCard from '../../ContentCard/TableCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { noWrap } from '../../../DataTransformFactory';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Formatter from '../../../FormatFactory';

export default function TransportTransporter(props) {
  const { t } = useTranslation('translation');
  const { transporter } = useParams();

  const perSource = {
    title: t('views.shipping.transporter.by_source'),
    subtitle: transporter,
    columns: [
      { key: 'source', label: t('views.shipping.transporter.cols.source') },
      {
        key: 'shipped_package_count',
        label: t('views.shipping.cols.shipped_package_count'),
        formatter: Formatter.number,
      },
      {
        key: 'average_total_weight_in_kg',
        label: t('views.shipping.cols.average_total_weight_in_kg'),
        formatter: Formatter.round2,
      },
      {
        key: 'avg_package_value_euro_cents',
        label: t('views.shipping.cols.avg_package_value_euro'),
        formatter: Formatter.euroCentsToEurosWithoutCents,
      },
      {
        key: 'FR',
        label: t('views.shipping.transporter.cols.fromFR'),
        formatter: Formatter.number,
      },
      {
        key: 'EU',
        label: t('views.shipping.transporter.cols.fromEU'),
        formatter: Formatter.number,
      },
      {
        key: 'outside_eu',
        label: t('views.shipping.transporter.cols.from_outside'),
        formatter: Formatter.number,
      },
    ],
    id_key: 'source',
    api: {
      module: 'analytics',
      path: 'carrier_use/by_source/v3',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
      queryparams: {
        carrier_name: transporter,
      },
    },
    pivotData: {
      api: {
        module: 'analytics',
        path: 'carrier_destination/by_source/v2',
        data_wrapper: noWrap,
        data_wrapper_opts: {
          data_key: 'data',
        },
        queryparams: {
          carrier_name: transporter,
        },
      },
      pivotable_options: ['FR', 'EU', 'outside_eu'],
      id_key: 'source',
      pivotable_label: 'area',
      pivotable_value: 'shipped_package_count',
    },
    component: TableCard,
  };

  const perOrigin = {
    title: t('views.shipping.transporter.by_origin'),
    subtitle: transporter,
    columns: [
      { key: 'origin', label: t('views.shipping.transporter.cols.origin') },
      {
        key: 'shipped_package_count',
        label: t('views.shipping.cols.shipped_package_count'),
        formatter: Formatter.number,
      },
      {
        key: 'average_total_weight_in_kg',
        label: t('views.shipping.cols.average_total_weight_in_kg'),
        formatter: Formatter.round2,
      },
      {
        key: 'avg_package_value_euro_cents',
        label: t('views.shipping.cols.avg_package_value_euro'),
        formatter: Formatter.euroCentsToEurosWithoutCents,
      },
      {
        key: 'FR',
        label: t('views.shipping.transporter.cols.fromFR'),
        formatter: Formatter.number,
      },
      {
        key: 'EU',
        label: t('views.shipping.transporter.cols.fromEU'),
        formatter: Formatter.number,
      },
      {
        key: 'outside_eu',
        label: t('views.shipping.transporter.cols.from_outside'),
        formatter: Formatter.number,
      },
    ],
    id_key: 'origin',
    disableLabelFormatter: true,

    api: {
      module: 'analytics',
      path: 'carrier_use/by_origin/v3',
      data_wrapper: noWrap,
      data_wrapper_opts: {
        data_key: 'data',
      },
      queryparams: {
        carrier_name: transporter,
      },
    },
    pivotData: {
      api: {
        module: 'analytics',
        path: 'carrier_destination/by_origin/v2',
        data_wrapper: noWrap,
        data_wrapper_opts: {
          data_key: 'data',
        },
        queryparams: {
          carrier_name: transporter,
        },
      },
      pivotable_options: ['FR', 'EU', 'outside_eu'],
      id_key: 'origin',
      pivotable_label: 'area',
      pivotable_value: 'shipped_package_count',
    },
    component: TableCard,
  };

  return [
    <BreadCrumb
      previousRoute="shipping"
      previousRouteLabel={t('menu.shipping')}
      key="breadcrumb"
    >
      {t('views.shipping.transporter.transporter')} :{' '}
      <span className="selectable">{transporter}</span>
    </BreadCrumb>,
    <div className="column is-full" key="per_source">
      <ContentCard {...props} {...perSource} />
    </div>,
    <div className="column is-full" key="per_origin">
      <ContentCard {...props} {...perOrigin} />
    </div>,
  ];
}
