import React, { createContext, useContext, useEffect, useState } from 'react';
import API, { headers, URL_MAP } from '../API';

export const DataContext = createContext(null);

export function useData() {
  return useContext(DataContext);
}

export const DataProvider = ({ children }) => {
  const [orderSources, setOrderSources] = useState([]);
  const [sourcesLoaded, setSourcesLoaded] = useState(false);

  useEffect(() => {
    loadOrderSource();
  }, []);

  const loadOrderSource = async () => {
    const { data } = await API.get(`${URL_MAP.OMS}client_order_sources`, {
      withCredentials: true,
      headers: {
        ...headers['OMS'],
      },
    });
    if (data.client_order_sources) {
      setOrderSources(data.client_order_sources);
    }
    setSourcesLoaded(true);
  };

  return (
    <>
      <DataContext.Provider value={{ orderSources, sourcesLoaded }}>
        {children}
      </DataContext.Provider>
    </>
  );
};
