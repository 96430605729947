import React from 'react';
import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import { useData, useFilter } from '../../providers';

export const OrderSourcesFilter = () => {
  const { saveFilter, filter } = useFilter();
  const { orderSources } = useData();

  return (
    <Autocomplete
      disablePortal
      multiple
      size="small"
      disableCloseOnSelect
      value={orderSources.reduce((acc, value) => {
        if ((filter.sources || []).includes(value.name)) {
          acc.push(value);
        }
        return acc;
      }, [])}
      options={orderSources}
      sx={{ minWidth: 300, height: 40 }}
      data-testid="order-sources"
      getOptionLabel={(option) => option.display_name}
      onChange={(_, values) => {
        if (values.length) {
          saveFilter({
            sources: values.map((v) => v.name),
          });
        } else {
          saveFilter({
            sources: null,
          });
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
            size={'small'}
          />
          {option.display_name || option.name}
        </li>
      )}
      renderTags={(options, getTagProps) => (
        <>
          {options.slice(0, 2).map((option, index) => (
            <Chip
              label={option.display_name || option.name}
              size={'small'}
              {...getTagProps({ index })}
            />
          ))}
          {options.length > 2 && <span>+{options.length - 2}</span>}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          margin={'dense'}
          size={'small'}
          sx={{ height: 40 }}
        />
      )}
      ListboxProps={{ 'data-testid': 'order-sources-list' }}
    />
  );
};
